<template>
  <v-container>
    <base-material-card
      color="primary"
      icon="mdi-file-tree"
      inline
      class="px-5 py-4 ml-0 pb-10"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
         Logs da importação #{{ importacao.id }}
        </div>
      </template>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            style="float: right !important"
            class="mt-n9" 
            v-bind="attrs" v-on="on"
          >
            <v-btn
              id="importacao-dados-logs-tecnicos"
              icon
              color="black"
              @click="baixarLogsTecnicos()"
              :loading="loadingLogsTecnicos"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="22">
                mdi-download-circle-outline
              </v-icon>
            </v-btn>
          </div>
        </template>
        Clique aqui para baixar os logs técnicos
      </v-tooltip>
      <v-divider class="my-4" />
      <span id="importacao-dados-logs-first-sec">
        <v-row>
          <v-col cols="6">
            <label class="d-block mb-1">
                Título da importação:
            </label>
            <v-label>{{ importacao.titulo }}</v-label>
          </v-col>
          <v-col cols="6">
            <label class="d-block mb-1">
                Empresa:
            </label>
            <v-label>{{ importacao.empresa }}</v-label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <label class="d-block mb-1">
                Descrição:
            </label>
            <v-label>{{ importacao.descricao ? importacao.descricao : '-' }}</v-label>
          </v-col>
          <v-col cols="6">
            <label class="d-block mb-1">
                Arquivo:
            </label>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <div
                        id="importacao-dados-baixar-arquivo"
                        class="clickable-item"
                        @click="baixarArquivo()"
                        style="display: inline !important"
                        v-bind="attrs" v-on="on"
                    >
                        {{ importacao.arquivo }} ({{ importacao.encoding }})
                        <v-progress-circular 
                            v-if="loading"
                            size="20"
                            indeterminate
                            color="rgba(0, 0, 0, 0.87)"
                        />
                    </div>
                </template>
                Clique aqui para baixar o arquivo {{ importacao.arquivo }}
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <label class="d-block mb-1">
                Tipo de importação:
            </label>
            <v-label>{{ importacao.tipo }}</v-label>
          </v-col>
          <v-col cols="6" v-if="importacao.data_registro">
            <label class="d-block mb-1">
                Mês dos dados:
            </label>
            <v-label>
              {{ importacao.data_registro | formatToMonth }} v{{ importacao.versao }}
            </v-label>
          </v-col>
          <v-col cols="6">
            <label class="d-block mb-1">
              Apagou base anterior?:
            </label>
            <v-label>
              {{ importacao.apagar_base_atual ? "Sim" : "Não" }}
            </v-label>
          </v-col>
          <v-col cols="6" v-if="isBaseIndger && !importacao.apagar_base_atual">
            <label class="d-block mb-1">
              Apagou aprovações de indicadores?:
            </label>
            <v-label>
              {{ importacao.apagar_aprovacoes_atuais ? "Sim" : "Não" }}
            </v-label>
          </v-col>
          <v-col v-if="importacao.temas_selecionados.length > 0" cols="6 ">
            <label class="d-block mb-1">
             Temas atualizados:
            </label>
            <v-label>
              <v-chip 
                v-for="tema in importacao.temas_selecionados_names"
                small
                outlined
                class="mr-1 mb-1"
              >
                {{ tema }}
              </v-chip>
            </v-label>
          </v-col>          
          <v-col v-if="importacao.categoria_servicos.length > 0" cols="12">
            <label class="d-block mb-1">
             Categorias de Serviço atualizadas:
            </label>
            <v-label>
              <v-chip 
                v-for="categoria in importacao.categoria_servicos"
                small
                outlined
                class="mr-1 mb-1"
              >
                {{ categoria }}
              </v-chip>
            </v-label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <label class="d-block mb-1">
                Criado por:
            </label>
            <v-label>{{ importacao.usuario }} em {{ importacao.criado_em }}</v-label>
          </v-col>
          <v-col cols="6">
            <label class="d-block mb-1">
                Logs da importação:
            </label>
            <v-tooltip v-if="importacao.log_importacao_existe" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        id="importacao-dados-logs-valid"
                        color="gray"
                        outlined
                        small
                        @click="baixarLogs()"
                        :loading="loadingLogs"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon left>
                            mdi-download-circle-outline
                        </v-icon>
                        Baixar
                    </v-btn>
                </template>
                Clique aqui para baixar os logs
            </v-tooltip>
            <v-label v-else>-</v-label>
          </v-col>
        </v-row>
      </span>
      <v-divider class="mt-2 mb-2" />
      <v-row v-if="importacao.status">
        <v-col cols="12">
          <import-status-card
            id="import-status-card"
            :status="importacao.status"
            :contadores="contadores"
          />
        </v-col>
      </v-row>
      <v-row
        id="import-status-tabela-contadores" 
        v-if="importacao.status"
      >
        <v-col cols="12">
          <tabela-contadores 
            :contadores="contadores"
            :versao="importacao.versao"
          />
        </v-col>
      </v-row>  
    </base-material-card>
  </v-container>
</template>

<script>

import ImportacoesDadosService from '@/services/ImportacoesDadosService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import tourMixins from '@/mixins/tourMixins';

export default {
  mixins: [
    refreshDataMixins, 
    tourMixins
  ],
  components: {
    ImportStatusCard: () => import('@/components/administracao/importacoes-dados/ImportStatusCard'),
    TabelaContadores: () => import('@/components/administracao/importacoes-dados/TabelaContadores.vue')  
  },
  data: () => ({
    importacao: {},
    loading: false,
    loadingLogs: false,
    loadingLogsTecnicos: false
  }),
  async created() {
    await this.refreshData(this.getLogsImportacao);
  },
  computed: {
    isBaseIndger() {
      const bases_indger = [
        "IndicadoresAlimentadores", 
        "IndicadoresComerciaisV2", 
        "IndicadoresLD", 
        "IndicadoresServicosV2", 
        "IndicadoresSubestacoes"
      ];

      return bases_indger.includes(this.importacao.tipo_base);
    },
    contadores() {
      let contadores = this.importacao && this.importacao.contadores ? JSON.parse(this.importacao.contadores) : null;
      contadores['statusValidacao'] = this.importacao.status_validacao;
      return contadores
    },
    arrTour() {
      let tour = [
        {
          element: '#importacao-dados-logs-first-sec',
          intro: 'Nesta seção é possivel visualizar as informações básicas da importação.',
          scrollTo: 'tooltip',
          position: 'bottom'
        },
        {
          element: '#importacao-dados-logs-tecnicos',
          intro: 'Clicando neste botão você poderá realizar o download dos logs técnicos da importação. Este log é mais conveniente para desenvolvedores e profissionais de TI.',
          scrollTo: 'tooltip',
          position: 'bottom'
        },
        {
          element: '#importacao-dados-baixar-arquivo',
          intro: 'Aqui você poderá baixar o arquivo que foi enviado como fonte de dados da importação.',
          scrollTo: 'tooltip',
          position: 'bottom'
        }
      ];

      if (this.importacao && this.importacao.log_importacao_existe) {
        tour.push({
          element: '#importacao-dados-logs-valid',
          intro: 'Clicando neste botão você poderá realizar o download dos logs da importação, com os arquivos de linhas não importáveis e linhas com erros.',
          scrollTo: 'tooltip',
          position: 'top'
        });
      };

      tour.push(
        {
          element: '#import-status-card',
          intro: 'Aqui é possivel visualizar o status da importação, linhas processadas e o progresso da importação.',
          scrollTo: 'tooltip',
          position: 'bottom'
        },
        {
          element: '#import-status-tabela-contadores',
          intro: 'Aqui é possivel visualizar os contadores resultado da importação, como o número de linhas processadas, linhas não importadas, número de linhas no arquivo, etc.',
          scrollTo: 'tooltip',
          position: 'top'
        },
        {
          element: '#linhas-importadas',
          intro: 'Linhas importadas exibe o número de linhas do arquivo que de fato foram inseridas ou atualizadas no banco de dados do sistema.',
          scrollTo: 'tooltip',
          position: 'top'
        },
        {
          element: '#linhas-nao-importadas',
          intro: 'Linhas não importadas são as linhas que não foram inseridas ou atualizadas devido a erros, como dado em um formato não esperado, por exemplo.',
          scrollTo: 'tooltip',
          position: 'top'
        },
        {
          element: '#linhas-importaveis',
          intro: 'Linhas importáveis são as linhas do arquivo com dados válidos para a importação (células de dados).',
          scrollTo: 'tooltip',
          position: 'top'
        },
        {
          element: '#linhas-nao-importaveis',
          intro: 'Linhas não importáveis são as linhas "lixo" do arquivo, como rodapé, marcas de impressão, etc.',
          scrollTo: 'tooltip',
          position: 'top'
        },
        {
          element: '#linhas-cabecalhos',
          intro: 'Linhas cabeçalhos são as linhas que são idênticas ao cabeçalho esperado do arquivo. Alguns arquivos modelos, como o de AIS, podem contêr repetições de cabeçalhos.',
          scrollTo: 'tooltip',
          position: 'top'
        },
        {
          element: '#numero-linhas',
          intro: 'O número de linhas encontrada no arquivo, incluindo linhas em branco, cabeçalhos, linhas importáveis e linhas não importáveis.',
          scrollTo: 'tooltip',
          position: 'top'
        }
      );

      return tour;
    },
  },

  methods: {
    getLogsImportacao() {

      if (!this.$route.params.id) {
        return;
      }

      ImportacoesDadosService
        .getLogsImportacao(this.$route.params.id)
        .then(importacao => {
            this.importacao = importacao;
            
            this.iniciarTourPagina();
         });
    },

    baixarArquivo() {
      
      this.loading = true;

      ImportacoesDadosService
        .baixarArquivo(this.importacao.arquivo_id)
        .then(res => {

            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.importacao.arquivo);
            document.body.appendChild(link);
            link.click();
        })
        .catch(() => {
            this.$toast.error(`Falha no download do arquivo ${this.importacao.arquivo}.`, '', { position: 'topRight' });
        })
        .finally(() => this.loading = false);
    },

    baixarLogs() {

      this.loadingLogs = true;

      ImportacoesDadosService
        .baixarLogs(this.importacao.id)
        .then(res => {

            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `logs-importacao-${this.importacao.id}.zip`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(() => {
            this.$toast.error('Falha no download dos logs de importação.', '', { position: 'topRight' });
        })
        .finally(() => this.loadingLogs = false);
    },

    baixarLogsTecnicos() {

      this.loadingLogsTecnicos = true;

      ImportacoesDadosService
        .baixarLogsTecnicos(this.importacao.id)
        .then(res => {
            
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `logs-tecnicos-importacao-${this.importacao.id}.log`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(() => {
            this.$toast.error('Falha no download dos logs técnicos.', '', { position: 'topRight' });
        })
        .finally(() => this.loadingLogsTecnicos = false);
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      this.iniciarTour(this.arrTour);
    }
  }
}
</script>